import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import GeneratorHeaderSection from '@sections/free-ai-tools-generators/GeneratorHeaderSection'
import personaIcon from '@assets/images/free-ai-tools/userPersonaGenerator.svg'
import UserPersonaGeneratorFormSection from '@sections/free-ai-tools-generators/user-persona-generator/UserPersonaGeneratorFormSection'
import GeneratorTextSection from '@sections/free-ai-tools-generators/GeneratorTextSection'
import GeneratorFAQSection from '@sections/free-ai-tools-generators/GeneratorFAQSection'
import GeneratorCTASection from '@sections/free-ai-tools-generators/GeneratorCTASection'

const UserPersonaGenerator = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allUserPersonaGeneratorFaqJson {
        edges {
          node {
            key
            head
            body
          }
        }
      }
    }
  `)
  return (
    <Layout pageName="user-persona-generator">
      <GeneratorHeaderSection
        titleIcon={personaIcon}
        title={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.subtitle' })}
      />
      <UserPersonaGeneratorFormSection />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.section01.title' })}
        description={[
          intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.section01.description01' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.section01.description02' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.section01.description03' }),
        ]}
      />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.section02.title' })}
        description={[intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.section02.description01' })]}
        list={[
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.user-persona-generator.section02.list.item01.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.user-persona-generator.section02.list.item01.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.user-persona-generator.section02.list.item02.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.user-persona-generator.section02.list.item02.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.user-persona-generator.section02.list.item03.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.user-persona-generator.section02.list.item03.regular-text',
            }),
          },
        ]}
      />
      <GeneratorFAQSection faqData={data.allUserPersonaGeneratorFaqJson} />
      <GeneratorCTASection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.sectionCTA.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.sectionCTA.subtitle' })}
        buttonText={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.sectionCTA.buttonText' })}
      />
    </Layout>
  )
}

export default injectIntl(UserPersonaGenerator)
