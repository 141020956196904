import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import FreeAiToolsFormWrapper from '@components/pages/free-ai-tools/FreeAiToolsFormWrapper'
import UserPersonaGeneratorForm from '@components/pages/free-ai-tools/forms/UserPersonaGeneratorForm'

const UserPersonaGeneratorFormSection = ({ intl }) => {
  return (
    <FreeAiToolsFormWrapper title={intl.formatMessage({ id: 'free-ai-tools-pages.user-persona-generator.form.title' })}>
      <UserPersonaGeneratorForm intl={intl} />
    </FreeAiToolsFormWrapper>
  )
}

export default injectIntl(UserPersonaGeneratorFormSection)
